/* Styles */
import '@/scss/main.scss'

/* Core */
import Vue from 'vue'
import Buefy from 'buefy'

/* Router & Store */
import router from './router'
import store from './store'

/* Vue. Main component */
import App from './App.vue'

import axios from 'axios'

import i18n from './common/plugins/vue-i18n'

/* Fetch sample data */
store.dispatch('fetch', 'clients')

/* Default title tag */
const defaultDocumentTitle = 'Dodo MAX'

/* Collapse mobile aside menu on route change & set document title from route meta */
router.afterEach(to => {
  store.commit('asideMobileStateToggle', false)

  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} — ${defaultDocumentTitle}`
  } else {
    document.title = defaultDocumentTitle
  }
})
router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !store.state.isLogin) {
    // redirect the user to the login page
    next({ name: 'login' })
  } else {
    next()
  }
})

axios.interceptors.response.use(
  response => {
    // store.state.isShow = false;
    if (response.data.error === '401'){
      router.push({ path: '/login' })
    }
    return response
  },
  error => {
    if (error.response){
      if (error.response.status === 401){
        router.push({ path: '/login' })
      }
    }
    return Promise.reject(error)
  }
)

Vue.config.productionTip = false

Vue.prototype._i18n = i18n
i18n.locale = 'hk'

Vue.use(Buefy)

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
