export const locale = {
  en: {
    message: 'test111',
    list: '列表',
    client: '客戶',
    name: '名稱',
    description: '描述',
    search: '搜索',
    reset: '清空',
    address: '地址',
    phone: '電話',
    sign_contact_name: '簽署人',
    head_contact_name: '聯絡人',
    add_new: '新增',
    post_code: '郵編',
    fax: '傳真',
    bank_acc: '銀行帳號',
    tax_acc: '稅號',
    bank: '銀行名稱',
    insert_succeeded: '新增成功',
    insert_failed: '新增失敗',
    detail: '明細',
    duplication_name: '名稱己經存在, 不能重複錄入',
    head_contact_id: '主要聯絡人',
    sign_contact_id: '簽名聯絡人',
    update_done: '更新成功',
    update_failed: '更新失敗',
    submit: '提交',
    close: '關閉',
    group_to_intersales: '合併生成銷售單據',
    select: '選擇',
    payContract: '合同支付管理',
    contract_no: '合同號',
    contract_id: '合同號',
    ref_no: '參考編號',
    comment: '發票號',
    amount: '總價',
    service_charge: '手續費',
    payment_date: '支付日期',
    status: '狀態',
    payment_id: '',
    payContractDetail: '支付明細',
    paid_amount: '支付總金額',
    contract_exits: '編號己存在',
    contract_empty: '編號不能為空',
    done: '操作完成!',
    deleting: '刪除記錄',
    confirm_delete: '確認刪除?',
    cancel: '取消',
    inter_sale_code: '銷售單號',
    payOrder: '銷售單據支付',
    payOrderDetail: '支付明細',
    inter_sale_id: '銷售單號',
    payment_detail_id: '',
    user: '用戶管理',
    first_name: '用戶名稱',
    user_name: '登錄名',
    role: '權限',
    fastPayOrder: '快速支付單據',
    pay: '支付',
    sale_date: '銷售日期',
    payment: '支付方式',
    uploadContract: '上傳識別合同',
    uploadInvoiceFile: '上傳識別供貨單'
  }
}
