<template>
  <section class="section is-main-section">
    <card-component
      title="Report"
      icon="ballot"
    >
          <card-component class="has-table has-mobile-sort-spaced">
            <b-table :data="data" :columns="columns" :bordered="true" striped :scrollable="true"  paginated
                     per-page="8"></b-table>
          </card-component>
    </card-component>
  </section>
</template>

<script>
import CardComponent from '@/components/CardComponent.vue'
import axios from 'axios'

export default {
  name: 'Home',
  data() {
    return {
      data: [],
      columns: [
        {
          field: 'SDATE',
          label: 'SDATE',
          headerClass: 'customer-table-header',
          cellClass: 'customer-table-cell ',
        },
        {
          field: 'SINVOICE',
          label: 'SINVOICE',
          headerClass: 'customer-table-header',
        },
        {
          field: 'SCUSTOMER',
          label: 'SCUSTOMER',
          width: '200',
          headerClass: 'customer-table-header',
        },
        {
          field: 'SQTY',
          label: 'SQTY',
          headerClass: 'customer-table-header',
        },
        {
          field: 'SAMOUNT',
          label: 'SAMOUNT',
          headerClass: 'customer-table-header',
        },
        {
          field: 'SDATE',
          label: 'SDATE',
          headerClass: 'customer-table-header',
        },
        {
          field: 'SREMARKS',
          label: 'SREMARKS',
          headerClass: 'customer-table-header',
        },
        {
          field: 'SREMARKS',
          label: 'SREMARKS',
          headerClass: 'customer-table-header',
        },
        {
          field: 'IDATE',
          label: 'IDATE',
          headerClass: 'customer-table-header',
        },
        {
          field: 'ISUPPLIERS',
          label: 'ISUPPLIERS',
          headerClass: 'customer-table-header',
        },
        {
          field: 'IITEM',
          label: 'IITEM',
          headerClass: 'customer-table-header',
        },
        {
          field: 'IQTY',
          label: 'IQTY',
          headerClass: 'customer-table-header',
        },
        {
          field: 'IAMOUNT',
          label: 'IAMOUNT',
          headerClass: 'customer-table-header',
        },
        {
          field: 'ISUPP',
          label: 'ISUPP',
          headerClass: 'customer-table-header',
        },
        {
          field: 'IDATESettle',
          label: 'IDATESettle',
          headerClass: 'customer-table-header',
        },
        {
          field: 'IREMARKS',
          label: 'IREMARKS',
          headerClass: 'customer-table-header',
        },
        {
          field: 'IETA',
          label: 'IETA',
          headerClass: 'customer-table-header',
        }
      ]
    }},
  components: {
    CardComponent
  },
  async created() {
    const temp = axios.get('http://localhost:8080/data-sources/bigData.json')
    const tempData = temp.data

    for (const i in tempData){
      this.data.push(tempData[i])
    }
  }
}
</script>
