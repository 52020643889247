export default [
  '主要功能',
  [
    {
      to: '/',
      icon: 'desktop-mac',
      label: '總報表'
    }
  ],
  'ERP',
  [
    {
      icon: 'account-box-multiple',
      label: '單據管理系統',
      menu: [
        {
          icon: 'account-group-outline',
          to: '/user',
          label: '用戶管理'
        },
        {
          icon: 'account-group-outline',
          to: '/innerSales',
          label: '銷售單據'
        },
        {
          icon: 'clipboard-file-outline',
          to: '/arrival',
          label: 'BASF 供貨單'
        },
        {
          icon: 'shopping-outline',
          to: '/innerSalesCreate',
          label: '生成銷售單據'
        },
        {
          icon: 'shopping-outline',
          to: '/product',
          label: '產品'
        },
        {
          icon: 'shopping-outline',
          to: '/contract',
          label: '合同'
        },
        {
          icon: 'shopping-outline',
          to: '/customer',
          label: '客戶'
        },
        {
          icon: 'shopping-outline',
          to: '/lead',
          label: '商機'
        },
        {
          icon: 'shopping-outline',
          to: '/order',
          label: '上海銷售單'
        },
      ]
    },
    {
      icon: 'bank',
      label: '財務',
      menu: [
        {
          icon: 'file-table-outline',
          to: '/payment/payContract',
          label: '合同支付管理'
        },
        {
          icon: 'file-table-outline',
          to: '/payment/payOrder',
          label: '銷售單據支付管理'
        }
      ]
    },
    {
      icon: 'hammer-screwdriver',
      label: '系統管理',
      menu: [
        {
          icon: 'account-box-multiple',
          to: '/user',
          label: '用戶管理'
        }
      ]
    }
  ],
]
