import Vue from 'vue'
import VueRouter from 'vue-router'
import Arrival from '@/views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: '供貨單'
    },
    path: '/',
    name: 'arrival',
    component: Arrival
  },
  {
    meta: {
      title: 'Tables'
    },
    path: '/tables',
    name: 'tables',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "tables" */ '@/views/Tables.vue')
  },
  {
    meta: {
      title: 'Forms'
    },
    path: '/forms',
    name: 'forms',
    component: () => import(/* webpackChunkName: "forms" */ '@/views/Forms.vue')
  },
  {
    meta: {
      title: 'Profile'
    },
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile.vue')
  },
  {
    meta: {
      title: 'New Client'
    },
    path: '/client/new',
    name: 'client.new',
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/ClientForm.vue')
  },
  {
    meta: {
      title: 'Edit Client'
    },
    path: '/client/:id',
    name: 'client.edit',
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/ClientForm.vue'),
    props: true
  },
  {
    meta: {
      title: 'Sales'
    },
    path: '/innerSales',
    name: 'innerSales',
    component: () => import(/* webpackChunkName: "client-form" */ '@/ERP/innerSales/index.vue'),
    props: true
  },
  {
    meta: {
      title: 'Sales Order'
    },
    path: '/innerSalesCreate',
    name: 'innerSalesCreate',
    component: () => import(/* webpackChunkName: "client-form" */ '@/ERP/innerSales/indexCreate.vue'),
    props: true
  },
  {
    meta: {
      title: 'arrival'
    },
    path: '/arrival',
    name: 'arrival',
    component: () => import('@/ERP/arrival/Index.vue'),
    props: true
  },
  {
    meta: {
      title: 'product'
    },
    path: '/product',
    name: 'product',
    component: () => import(/* webpackChunkName: "client-form" */'@/ERP/product/index.vue'),
    props: true
  },
  {
    meta: {
      title: 'contract'
    },
  path: '/contract',
  name: 'contract',
  component: () => import(/* webpackChunkName: "client-form" */'@/ERP/contract/index.vue'),
  props: true
},
  {
    meta: {
      title: 'Inventory'
    },
    path: '/inventory',
    name: 'inventory',
    component: () => import(/* webpackChunkName: "client-form" */ '@/ERP/Inventory.vue'),
    props: true
  },
  {
    meta: {
      title: 'Customer'
    },
    path: '/customer',
    name: 'customer',
    component: () => import(/* webpackChunkName: "client-form" */ '@/ERP/customer/index.vue'),
    props: true
  },
  {
    meta: {
      title: 'Pay Contract'
    },
    path: '/payment/payContract',
    name: 'payContract',
    component: () => import(/* webpackChunkName: "client-form" */ '@/ERP/payment/payContract.vue'),
    props: true
  },
  {
    meta: {
      title: 'Pay Contract'
    },
    path: '/payment/fastPayOrder',
    name: 'payContract',
    component: () => import(/* webpackChunkName: "client-form" */ '@/ERP/payment/fastpayOrder.vue'),
    props: true
  },
  {
    meta: {
      title: 'Pay Order'
    },
    path: '/payment/payOrder',
    name: 'payOrder',
    component: () => import(/* webpackChunkName: "client-form" */ '@/ERP/payment/payOrder.vue'),
    props: true
  },
  {
    meta: {
      title: 'User'
    },
    path: '/user',
    name: 'user',
    component: () => import(/* webpackChunkName: "client-form" */ '@/ERP/user/index.vue'),
    props: true
  },
  {
    meta: {
      title: 'Lead'
    },
    path: '/lead',
    name: 'lead',
    component: () => import(/* webpackChunkName: "client-form" */ '@/ERP/lead/index.vue'),
    props: true
  },
  {
    meta: {
      title: 'Order'
    },
    path: '/order',
    name: 'order',
    component: () => import(/* webpackChunkName: "client-form" */ '@/ERP/order/index.vue'),
    props: true
  },
  {
    meta: {
      title: 'AP'
    },
    path: '/ap',
    name: 'ap',
    component: () => import(/* webpackChunkName: "client-form" */ '@/ERP/AP.vue'),
    props: true
  },
  {
    path: '/full-page',
    component: () => import(/* webpackChunkName: "full-page" */ '@/views/FullPage.vue'),
    children: [
      {
        meta: {
          title: 'Login'
        },
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "full-page" */ '@/views/full-page/Login.vue')
      }
    ]
  },
  {
    meta: {
      title: 'upload'
    },
    path: '/upload',
    name: 'upload',
    component: () => import(/* webpackChunkName: "client-form" */ '@/ERP/upload.vue'),
    props: true
  },

]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router

export function useRouter () {
  return router
}
